import React from "react"
import "./app.scss";
import "tachyons";
import { Router } from "@reach/router"


import { AuthProvider } from "../containers/auth/Auth";
import PrivateRoute from "../containers/privateRoute/PrivateRoute";
import ErrorBoundary from "../containers/errorBoundary/ErrorBoundary";

import ManualSizeEntry from "../containers/manualSizeEntry/ManualSizeEntry";
import SizeCalcuMain from "../containers/sizeCalcuMain/SizeCalcuMain";
import SizeCalcuExternal from "../containers/sizeCalcuExternal/SizeCalcuExternal";
import Login from "../containers/login/Login";
import SignUp from "../containers/signup/SignUp";
import Account from "../containers/account/Account";
import MySize from "../containers/mySize/MySize";
import ChooseSizeOptionExternal from "../containers/chooseSizeOptionExternal/ChooseSizeOptionExternal";
import {   ButtonBack,

  CarouselProvider,

 } from 'pure-react-carousel';

import PageNotFound from "../components/pageNotFound/PageNotFound";

const App = () => {
  return (
    <AuthProvider> 
      <CarouselProvider
                naturalSlideWidth={100}
                totalSlides={8}
                dragEnabled={false}
                isIntrinsicHeight
                style={{margin:'2rem 0 0 0', }}
      >
      <div data-theme="light">
      
        <ErrorBoundary>
          <Router basepath="/app">
            <PrivateRoute path="/choose-size-option-external" component={ChooseSizeOptionExternal} />
            <PrivateRoute path="/manual-size-entry" component={ManualSizeEntry} />
            <PrivateRoute path="/size-calculator-main" component={SizeCalcuMain} />
            <PrivateRoute
                  path="/size-calculator-external"
                  component={SizeCalcuExternal}
                />
             <PrivateRoute  path="/account" component={Account} />
              <PrivateRoute  path="/my-size" component={MySize} />
              <Login path="/login" component={Login} />
              <SignUp  path="/signup" component={SignUp} />
 
             
              <PageNotFound path="*" component={PageNotFound} />
          </Router>
        </ErrorBoundary>
     
      </div> 
      </CarouselProvider>
    </AuthProvider>
   
  )
}

export default App