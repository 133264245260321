import React from "react";
import SizeCalcu from "../sizeCalcu/SizeCalcu";
import { useLocation } from "@reach/router"

const SizeCalcuMain = () => {
  const location = useLocation();
  return <SizeCalcu modeType="main" location={location} />;
};


export default SizeCalcuMain;
