import React, { useContext, useState } from "react";
import { AuthContext } from "../auth/Auth";
import { navigate } from '@reach/router';
import CodeLoader from "../../components/codeLoader/CodeLoader";
import firebase from "../../components/firebase/firebase";
import Nav from "../nav/Nav";
import PopUpModal from '../../components/popUpModal/PopUpModal'
import Footer from "../../components/footer/Footer";
import "./Account.scss";

const Account = () => {
  const { accountInfo,setCurrentUser } = useContext(AuthContext);
  const [confirmDelete, setConfirmDelete] = useState(false)
  if (accountInfo === null || accountInfo === undefined) {
    return <CodeLoader />;
  }
  const handleDeleteUser = async()=>{
    
    const deleteUserData =  firebase.functions().httpsCallable("deleteUser");
    deleteUserData().then(data=> {
      console.log(data)
      setCurrentUser(null)

      return 
    })

   

    return 
  }

  let modalPopUp
  if(confirmDelete){
    modalPopUp = (
      <PopUpModal
      modalVisible={()=>{handleDeleteUser()}}
      modalVisible2 = {()=>setConfirmDelete(false)}
      title="Are you sure?"
      subTitle='This action will permanently delete all data. '
      button1Text="Yes, delete account"
      button2Text='No, go back'
    />

    )
  }

  return (
    <div>
      <Nav />
      {modalPopUp}
      <div className="account-section_container">
        <div className="account-info_container">
          <h2 className="account-section_title">Account</h2>
          <h3 className="account-subTitle">Email: {accountInfo.email}</h3>
          <button
            className="btn btn-primary"
            onClick={() => {setConfirmDelete(true)}}
          >
            Delete Account
          </button>
        </div>
        
      </div>
      <Footer />
    </div>
  );
};

export default Account;
