import React, { useState, useContext, useEffect } from "react";
import { navigate } from '@reach/router';
import { Link } from "gatsby"
import { AuthContext } from "../../containers/auth/Auth";
import Nav from "../../containers/nav/Nav";
import Footer from "../footer/Footer";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import * as QueryString from "query-string";
import { Player } from "@lottiefiles/react-lottie-player";
import SizifyLoading from "../../images/sizify-loading.json";
import Logo from "../../images/logo-04.png";
import "firebase/auth";
import firebase from "../firebase/firebase";

import "../../containers/login/Login.css";
import "../../containers/signup/SignUp.css";

if (typeof window !== `undefined`) {
  var firebaseui = require('firebaseui');
}

const UserSignIn = ({
  location,
  login,
  signup,
  title,
  subTitle,
  alterSignInText,
  alterSignInLinkText,
  alterSignInLink
}) => {
 
  const params = QueryString.parse(location.search);
  const { accountInfo, currentUser } = useContext(AuthContext);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false)

  useEffect(() => {
    //if coming from an external site signout user, give them a chance to sign back in
    if (params.redirectUrl !== undefined && params.external !== undefined) {
      firebase
        .auth()
        .signOut()
        .then(function () {
          setIsSignedIn(false);
          console.log("Sign-out successful.");
        })
        .catch(function (error) {
          console.log("An error happened.");
        });
    }
  }, []);

  useEffect(() => {
    if (currentUser !== null) {
      setIsSignedIn(true);
    }
  }, [currentUser]);

  let content

  if(true){
      content = (
        <div className="growthChecklistTY-list-container" style={{maxWidth:'24rem'}}>
          <div className="growthChecklistTY-list-section">
            <div>
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" aria-labelledby="okIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="okIconTitle">Ok</title> <polyline points="4 13 9 18 20 7"/> </svg> 
            </div>
            <h3 className='growthChecklistTY-list-text'>
             Worried about ordering the wrong size? 
            </h3>
          </div>
          <div className="growthChecklistTY-list-section">
            <div>
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" aria-labelledby="okIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="okIconTitle">Ok</title> <polyline points="4 13 9 18 20 7"/> </svg> 
            </div>
            <h3 className='growthChecklistTY-list-text'>
            Sign-up in under 2 minutes - get proper-fit
            </h3>
          </div>
          <div className="growthChecklistTY-list-section">
            <div>
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" aria-labelledby="okIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="okIconTitle">Ok</title> <polyline points="4 13 9 18 20 7"/> </svg> 
            </div>
            <h3 className='growthChecklistTY-list-text'>
            Sign-in available across stores in 1-click 
            </h3>
          </div>
          
        </div>
      )
  }
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",


    // We will display Google and Facebook as auth providers.
    signInOptions: [
      
      {
        provider:firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account'
        }
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
      },
     
    
     
    ],
    callbacks: {
      // Avoid redirects after sign-in.

      signInSuccessWithAuthResult: () => {
        return false;
      },
    },
  };

  const uiConfigExternal = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",


    // We will display Google and Facebook as auth providers.
    signInOptions: [
      
      {
        provider:firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account'
        }
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
      },
      {
        provider: firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
      },
     
    
     
    ],
    callbacks: {
      // Avoid redirects after sign-in.

      signInSuccessWithAuthResult: () => {
        return false;
      },
    },
  };
  

  if (isSignedIn && accountInfo !== null && accountInfo !== undefined && !isNavigating ) {
    let mySizeArray = [
      accountInfo.size.chest,
      accountInfo.size.hip,
      accountInfo.size.leg,
      accountInfo.size.waist,
    ];

    if (params.redirectUrl !== undefined && params.external !== undefined) {
      if (mySizeArray.includes(0)) {
        
          // navigate(`/app/size-calculator-external?external=${params.external}&redirectUrl=${params.redirectUrl}`)
          navigate(`/app/choose-size-option-external?external=${params.external}&redirectUrl=${params.redirectUrl}`)
          setIsNavigating(true)
          return null
        
      } else {
        let redirectUrlArray = params.redirectUrl.split("");
        if (redirectUrlArray.includes("?")) {
          navigate(`${params.redirectUrl}&sizify-id=${accountInfo.sizeId.id}`);
          setIsNavigating(true)
          return null
        } else {
          navigate(`${params.redirectUrl}?&sizify-id=${accountInfo.sizeId.id}`)
          setIsNavigating(true)
          return null
        }
      }
    } else if (login) {
      navigate("/app/my-size")
      setIsNavigating(true)
      return null ;
    } else {
      navigate("/app/size-calculator-main")
      setIsNavigating(true)
      return null ;
    }
  }

  if (isSignedIn) {
    return (
      <div className="login-section_bg-external">
        <h3 className="login-section_title">One Moment Please</h3>
        <Player
          autoplay
          loop
          src={SizifyLoading}
          style={{ height: "300px", width: "300px" }}
        ></Player>
      </div>
    );
  }

  if (params.external === "shopify" && params.redirectUrl !== undefined) {
    return (
      <div className="login-section_bg-external">
        <div className="login-closeButton_container">
          <a href={params.redirectUrl}>
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="36px"
              height="36px"
              viewBox="0 0 24 24"
              aria-labelledby="closeIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
            >
              {" "}
              <title id="closeIconTitle">Close</title>{" "}
              <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
            </svg>
          </a>
        </div>

        <div className="login-section_container-external">
          <div className="navbar-logo_container">
            <img src={Logo} alt="logo" className="navbar-logo" />
            <h1 className="navbar-logo_text">Sizify</h1>
          </div>
          <h2 className="login-section_title-external">{subTitle}</h2>
          {content}
          <StyledFirebaseAuth
            uiConfig={uiConfigExternal}
            firebaseAuth={firebase.auth()}
          />
          <h3 className="login-section_subTitle">
            {alterSignInText}{" "}
            <Link className="login-section_subTitleLink " to={`${alterSignInLink}?external=shopify&redirectUrl=${params.redirectUrl}`}>
              {alterSignInLinkText}
            </Link>
          </h3>
        </div>
      </div>
    );
  }

  console.log(title)

  return (
    <div>
      <Nav />
      <div className="login-section_bg">
        <div className="login-section_container">
          <h2 className="login-section_title">{title}</h2>
          {content}
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
          <h3 className="login-section_subTitle">
            {alterSignInText}{" "}
            <Link className="login-section_subTitleLink " to={alterSignInLink}>
              {alterSignInLinkText}
            </Link>
          </h3>
        </div>
      </div>
      <Footer />
    </div>
  );
 
};

export default UserSignIn;
