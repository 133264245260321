import React from 'react'
import { useLocation } from "@reach/router"

import ChooseSizeOption from '../chooseSizeOption/ChooseSizeOption'

const ChooseSizeOptionExternal = () => {
    const location = useLocation();
    return (
        <ChooseSizeOption location={location}>

        </ChooseSizeOption>
    )
}

export default ChooseSizeOptionExternal
