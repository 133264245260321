import React, {useState,useContext} from 'react'
import firebase from "../../components/firebase/firebase"
import { AuthContext } from "../auth/Auth";
import { useFormik } from 'formik';
 import * as Yup from 'yup';
 import { Link } from "gatsby"
import * as QueryString from "query-string";
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { useLocation } from "@reach/router"
import { Player } from "@lottiefiles/react-lottie-player";
import SizifyLoading from "../../images/sizify-loading.json";
import './ManualSizeEntry.css'

let classNames = require("classnames");
const ManualSizeEntry = () => {
  const location = useLocation();
  const params = QueryString.parse(location.search);
  const { accountInfo, sizeId } = useContext(AuthContext);
  const [unitSymbol, setUnitSymbol] = useState("inches");
  const [isLoading, setIsLoading] = useState(false)
  
    const formik = useFormik({
        initialValues: {
          waist: '',
          hip: '',
          leg: '',
          chest:''
        },
        validationSchema: Yup.object({
          waist: Yup
            .number()
            .positive('number must be greater than 0')
            .required('Required'),
          hip: Yup
            .number()
            .positive('number must be greater than 0')
            .required('Required'),
          leg: Yup
            .number()
            .positive('number must be greater than 0')
            .required('Required'),
          chest: Yup
            .number()
            .positive('number must be greater than 0')
            .required('Required'),
        }),
        onSubmit: values => {
          console.log('submit fired')
          setIsLoading(true)
          let waist = values.waist
          let hip = values.hip
          let leg = values.leg
          let chest = values.chest
          if(unitSymbol === "inches"){
            waist = Math.round(values.waist * 2.54);
            hip = Math.round(values.hip * 2.54);
            leg = Math.round(values.leg * 2.54);
            chest = Math.round(values.chest * 2.54);
          }
          const updateSizeManuallyFunc = firebase.functions().httpsCallable("updateSizeManually");
          updateSizeManuallyFunc({
            size: {
              waist,
              hip,
              leg,
              chest
            },
          })
          .then(() => {
              let redirectUrlArray = params.redirectUrl.split("");
              if (redirectUrlArray.includes("?")) {
                return (window.location.href = `${params.redirectUrl}&sizify-id=${accountInfo.sizeId.id}`);
              } else {
                return (window.location.href = `${params.redirectUrl}?&sizify-id=${accountInfo.sizeId.id}`);
              }

          })
          .catch((err) => {
            console.log(err.code, err.message, err.details);
          });

        },
      });

      let inchesClass = classNames({
        "btn btn-primary manualSizeEntry-unitButton ": unitSymbol === "inches",
        "btn btn-outline-secondary manualSizeEntry-unitButton ": unitSymbol !== "inches",
      });
      let cmClass = classNames({
        "btn btn-primary manualSizeEntry-unitButton ": unitSymbol !== "inches",
        "btn btn-outline-secondary manualSizeEntry-unitButton  ": unitSymbol === "inches",
      });

      if(isLoading){
        return(
          <div className='sizeCalcu-loading_container'>
          <h2 className="sizeCalcu-view_title">Processing</h2>
          <Player
            autoplay
            loop
            src={SizifyLoading}
            style={{ height: "300px", width: "300px" }}
          ></Player>
        </div>

        )


      }
      return (
        <div className='manualSizeEntry-containerBgExternal '>
            <div className='manualSizeEntry-closeButton_container'>
            <a href={params.redirectUrl}>
                  <svg
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36px"
                  height="36px"
                  viewBox="0 0 24 24"
                  aria-labelledby="closeIconTitle"
                  stroke="hsla(221, 70%, 70%, 1)"
                  strokeWidth="1"
                  strokeLinecap="square"
                  strokeLinejoin="miter"
                  fill="none"
                  color="hsla(221, 70%, 70%, 1)"
                  >
                  {" "}
                  <title id="closeIconTitle">Close</title>{" "}
                  <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
                  </svg>
              </a>
            </div>
            <div className='manualSizeEntry-header_container '>
              <ProgressBar title={'Get instant fit-match upon completion'} percent={100}/>
            </div>
            <form onSubmit={formik.handleSubmit} className='manualSizeEntry-header_container '>
              <h1 className='manualSizeEntry-title'>Enter Measurements</h1>
              <h3 className="manualSizeEntry-header_subTitleThin" style={{textAlign:'left', padding:'0 1rem', margin:'0 0 1rem 0'}}>
                Setup required only once. Your preferences will be saved for future visits. 
              </h3>
              <div className='manualSizeEntry-unit' >
                  <button
                  className={inchesClass}
                  onClick={() => {
                    setUnitSymbol('inches')
                  }}
                >
                  Inches
                </button>
                <button
                  className={cmClass}
                  onClick={() => {
                    setUnitSymbol('cm')
                  }}
                >
                  Cm
                </button>
              </div>
              <div className='manualSizeEntry-inputContainer'>
                <label htmlFor="waist" className='manualSizeEntry-input_title '>Waist (Measured Around)</label>
                <input
                  id="waist"
                  name="waist"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.waist}
                  className='btn btn-solid-outline-secondary '
                />
                {formik.touched.waist && formik.errors.waist ? (
                  <div>{formik.errors.waist}</div>
                ) : null}
              </div>
              <div className='manualSizeEntry-inputContainer'>
                <label htmlFor="hip" className='manualSizeEntry-input_title '>Hip (Measured Around)</label>
                <input
                  id="hip"
                  name="hip"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.hip}
                  className='btn btn-solid-outline-secondary '
                />
                {formik.touched.hip && formik.errors.hip ? (
                  <div>{formik.errors.hip}</div>
                ) : null}
              </div>
        
              <div className='manualSizeEntry-inputContainer'>
                <label htmlFor="leg" className='manualSizeEntry-input_title '>Leg (Inseam)</label>
                <input
                  id="leg"
                  name="leg"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.leg}
                  className='btn btn-solid-outline-secondary '
                />
                {formik.touched.leg && formik.errors.leg ? (
                  <div>{formik.errors.leg}</div>
                ) : null}
              </div>
              <div className='manualSizeEntry-inputContainer'>
                <label htmlFor="chest" className='manualSizeEntry-input_title '>Chest (Measured Around)</label>
                  <input
                    id="chest"
                    name="chest"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.chest}
                    className='btn btn-solid-outline-secondary '
                  />
                  {formik.touched.chest && formik.errors.chest ? (
                    <div>{formik.errors.chest}</div>
                  ) : null}
              </div>

              <div className='manualSizeEntry-button_container'>
                <button type="submit" className='btn btn-primary'>Submit</button>
              </div>
     
            </form>
  
        </div>
      );
    
}

export default ManualSizeEntry
