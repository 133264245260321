import React, { useState } from "react";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import "firebase/auth";
import firebase from "../../components/firebase/firebase";
import { useLocation } from "@reach/router"
import UserSignIn from "../../components/userSignIn/UserSignIn";

import "./SignUp.css";

const SignUp = () => {
  const location = useLocation();
  return (
    <UserSignIn
      location={location}
      title={"Sign Up"}
      subTitle={"Get instant personalized <span className='nobr'>fit recommendations.</span> "}
      alterSignInText={"Already a customer?"}
      alterSignInLinkText={"Log In"}
      alterSignInLink={"/app/login"}
    />
  );
};

export default SignUp;
