import React from 'react'
import { Line } from 'rc-progress';
const ProgressBar = ({title,percent}) => {
  return (
    <>
        <h4 className='chooseSizeOption-button_subTitle' style={{width:'100%'}}>{title}</h4>
         <Line percent={percent} strokeWidth={1} strokeColor="hsla(101, 55%, 55%, 1)" trailColor='hsla(210, 38%, 97%, 1)' trailWidth={1} />
    </>
  )
}

export default ProgressBar