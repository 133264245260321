import React, { useState, useEffect, useContext } from "react";
import firebase from "../../components/firebase/firebase"
import { AuthContext } from "../auth/Auth";

import Img from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"


import Nav from "../nav/Nav";
import Footer from "../../components/footer/Footer";

import * as tf from "@tensorflow/tfjs-core";
import * as bodyPix from "@tensorflow-models/body-pix";
import imageCompression from "browser-image-compression";
import { Player } from "@lottiefiles/react-lottie-player";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import ModalVideo from 'react-modal-video'
import {   ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
  CarouselProvider,
  CarouselContext,
  DotGroup,
  ImageWithZoom,
  Slide,
  Slider,
  Dot
 } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css'; // requires a loader
import SizifyLoading from "../../images/sizify-loading.json";
import CheckCircle from "../../images/check-circle-success.json";
import PopUpModal from "../../components/popUpModal/PopUpModal";
import "./SizeCalcu.css";
import Video from "../../images/video-file.png";
import Calculator from "../../images/size-calculator.png";
import * as QueryString from "query-string";

import ModelFront from "../../images/front-pose.png";
import ModelSide from "../../images/side-pose.png";
let classNames = require("classnames");

let db = firebase.firestore();


const SizeCalcu = ({location, modeType}) => {
  const data = useStaticQuery(graphql`
  query {
    twoPosesImg: file(relativePath: { eq: "2 poses.png" }) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backgroundImg: file(relativePath: { eq: "background.png" }) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  
      clothingImg: file(relativePath: { eq: "clothing.png" }) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  
      distanceFloorImg: file(relativePath: { eq: "distance-floor.png" }) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    
      hairImg: file(relativePath: { eq: "hair.png" }) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      phoneFloorImg: file(relativePath: { eq: "phone-floor.png" }) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lightingImg: file(relativePath: { eq: "lighting.png" }) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    
    }
  `)

  const twoPosesImg = data.twoPosesImg.childImageSharp.fluid
  const backgroundImg = data.backgroundImg.childImageSharp.fluid
  const clothingImg = data.clothingImg.childImageSharp.fluid
  const distanceFloorImg = data.distanceFloorImg.childImageSharp.fluid
  const hairImg = data.hairImg.childImageSharp.fluid
  const phoneFloorImg = data.phoneFloorImg.childImageSharp.fluid
  const lightingImg = data.lightingImg.childImageSharp.fluid
  
  const params = QueryString.parse(location.search);
  const { accountInfo, sizeId } = useContext(AuthContext);
  console.log(accountInfo);
  const [redirectExternal, setRedirectExternal] = useState(false);
  const [image, setImage] = useState(ModelFront);
  const [imageData1, setImageData1] = useState(null);
  const [imageData2, setImageData2] = useState(null);
  const [imageCount, setImageCount] = useState(0); //set to 0
  const [resultImage, setresultImage] = useState({});
  const [heightInCm, setHeightInCm] = useState(false); //set to false
  const [heightInCmData, setHeightInCmData] = useState(162.56);
  const [heightInputOption, setHeightInputOption] = useState("feet");
  const [feet, setFeet] = useState(5);
  const [inch, setInch] = useState(4);

  const [proceed, setProceed] = useState(false); //set to false
  const [confirmView, setConfirmView] = useState(false); // set to false
  const [confirmUpload, setConfirmUpload] = useState(false); //set to false
  const [isImageUpload, setIsImageUpload] = useState(false); // set to false
  const [tryNewImageModal, setTryNewImageModal] = useState(false);
  const [sizeInfo, setSizeInfo] = useState(null)
  const [successAnimation, setSuccessAnimation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('One Moment')
  const [isDemoVidOpen, setIsDemoVidOpen] = useState(false)
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);

  useEffect(() => {
    // check if account size is not 0 => redirect to external url
    if (accountInfo !== null) {
      console.log('sizeInfo effetc 2')
      const sizeArray = [
        accountInfo.sizeId.size.chest,
        accountInfo.sizeId.size.hip,
        accountInfo.sizeId.size.leg,
        accountInfo.sizeId.size.waist,
      ];
      if (
        !sizeArray.includes(0) &&
        imageCount === 2 &&
        modeType === "external"
      ) {
        console.log('sizeInfo effetc 3')
        setSuccessAnimation(true);
        setTimeout(() => {
          setSuccessAnimation(false);
          setRedirectExternal(true);
        }, 1500);
      }
    }
  }, [accountInfo]);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);
  


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [proceed]);

  useEffect(() => {
    let mounted = true;
    if (imageCount === 0 && isImageUpload === true && confirmUpload === true) {
      let img = document.getElementById("image");
      console.log(img);
      setLoading(true);
      let canvas = document.getElementById("canvas");

      const jpegFileCreate = () => {
        function loadImage(img, callback) {
          img.onload = callback();
        }

        loadImage(img, function () {
          console.log("oookk");
          let width = img.width;
          let height = img.height;
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          let jpegFile = canvas.toDataURL("image/jpeg");
          const sizeCalcuFunc = firebase.functions().httpsCallable("sizeCalcu");
          sizeCalcuFunc({
            view: 1,
            img: jpegFile,
            width: width,
            height: height,
          })
            .then((data) => {
              if (mounted) {
                document.getElementById(
                  "resultImage"
                ).innerHTML = `<img src='${data.data.newMask}' class='sizeCalcu-view_image' alt='the image'>`;
                setImageData1(data);
                setLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err.code, err.message, err.details);
            });
        });
      };
      jpegFileCreate();
    }
    return () => {
      mounted = false;
    };
  }, [confirmUpload]);

  useEffect(() => {
    let mounted = true;
    if (imageCount === 1 && isImageUpload === true && confirmUpload === true) {
      let img = document.getElementById("image");

      setLoading(true);
      let canvas = document.getElementById("canvas");

      const jpegFileCreate = () => {
        function loadImage(img, callback) {
          img.onload = callback();
        }
        loadImage(img, function () {
          let width = img.width;
          let height = img.height;
          console.log(width, height);
          canvas.width = width; // Set the width of the Canvas
          canvas.height = height; // Set the height of the Canvas
          let ctx = canvas.getContext("2d"); // Get the "context" of the canvas
          ctx.drawImage(img, 0, 0, width, height); // Draw your image to the canvas
          let jpegFile = canvas.toDataURL("image/jpeg");
          const sizeCalcuFunc = firebase.functions().httpsCallable("sizeCalcu");
          sizeCalcuFunc({
            view: 3,
            img: jpegFile,
            width: width,
            height: height,
          })
            .then((data) => {
              if (mounted) {
                document.getElementById(
                  "resultImage"
                ).innerHTML = `<img src='${data.data.newMask}' class='sizeCalcu-view_image' alt='the image'>`;
                setImageData2(data);
                setLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err.code, err.message, err.details);
            });
        });
      };
      jpegFileCreate();
    }
    return () => {
      mounted = false;
    };
  }, [confirmUpload]);

  useEffect(() => {
    if (heightInputOption === "feet") {
      handleFeetChangeUseEffect();
    }
  }, [heightInputOption]);

  useEffect(() => {
    if (imageCount === 1 && image === ModelFront) {
      setImage(ModelSide);
    }
  }, [image]);

  const onImageUpload = (e) => {
    const options = { maxWidthOrHeight: 1700 };
    imageCompression(e, options)
      .then((compressedImage) => {
        const imageURL = URL.createObjectURL(compressedImage);
        setImage(imageURL);
        setConfirmView(false);
        setIsImageUpload(true);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };

  const onChangeHeight = (e) => {
    const num = parseInt(e);
    if (isNaN(num)) {
      setHeightInCmData("");
    } else {
      setHeightInCmData(num);
    }
  };

  const toggleUnit = (optionType) => {
    setHeightInputOption(optionType);
  };

  const onClickProceed = (e) => {
    setProceed(true);
  };

  const backStepImageCount = (type) => {
    if (type === "invalid") {
      setIsImageUpload(false);
      setConfirmUpload(false);
      setImage(ModelFront);
      document.getElementById("resultImage").innerHTML = `<div></div>`;
      setTryNewImageModal(true);
    } else if(type === "instructions"){
      setIsImageUpload(false);
      setConfirmUpload(false);
      setProceed(false)
      setImageCount(0)
      setImage(ModelFront);
      setTryNewImageModal(true);
    } else {
      setIsImageUpload(false);
      setConfirmUpload(false);
      setImage(ModelFront);
    }
  };

  const onClickConfirmUpload = () => {
    setConfirmUpload(true);
  };

  const onClickConfirmView = () => {
    document.getElementById("resultImage").innerHTML = `<div></div>`;
    setConfirmView(true);
    setIsImageUpload(false);
    setConfirmUpload(false);
    setImageCount((imageCount) => imageCount + 1);
    setImage(ModelFront);
  };

  const onClickHeight = () => {
    setHeightInCm(true);
  };

  const handleFeetChange = (input, type) => {
    if (type === "feet") {
      setFeet(parseInt(input));
      let feetInCm = input * 30.48;
      let inchInCm = inch * 2.54;
      let newHeightInCm = feetInCm + inchInCm;
      setHeightInCmData(newHeightInCm);
    } else {
      setInch(parseInt(input));
      let inchInCm = input * 2.54;
      let feetInCm = feet * 30.48;
      let newHeightInCm = feetInCm + inchInCm;
      setHeightInCmData(newHeightInCm);
    }
  };

  const handleFeetChangeUseEffect = () => {
    let feetInCm = feet * 30.48;
    let inchInCm = inch * 2.54;
    let newHeightInCm = feetInCm + inchInCm;
    setHeightInCmData(newHeightInCm);
  };

  const toggleTryNewImage = () => {
    return tryNewImageModal
      ? setTryNewImageModal(false)
      : setTryNewImageModal(true);
  };
  const handleDynamicLoading = () =>{
    setTimeout(() => {
      setLoadingText('Finding Tailor')
    }, 5000);
    setTimeout(() => {
      setLoadingText('Taking Measurements')
    }, 10000);
    setTimeout(() => {
      setLoadingText('Recording Results')
    }, 15000);
    setTimeout(() => {
      setLoadingText('Finishing up')
    }, 20000);
  }
  let videoModal
  if (typeof window !== `undefined`) {
    videoModal = (<ModalVideo channel='youtube' autoplay isOpen={isDemoVidOpen} videoId="lD6WFSb0DNo?rel=0" onClose={() => setIsDemoVidOpen(false)} />)
   }


  // calculate final body measurentments
  if (imageCount === 2 && isImageUpload === false && confirmView === true) {
    let mounted = true;
    setLoading(true);
    const bodyCalcuFunc = firebase.functions().httpsCallable("bodyCalcu");
    bodyCalcuFunc({
      imageData1: imageData1,
      imageData2: imageData2,
      heightInCmData: heightInCmData,
    })
      .then((data) => {
        if (mounted) {
          console.log(data);
          setresultImage(data);
          setLoading(false);
          handleDynamicLoading()
        }
      })
      .catch((err) => {
        setLoading(false);
        backStepImageCount("instructions")
        console.log(err.code, err.message, err.details);
      });
    setConfirmView(false);
    return () => {
      mounted = false;
    };
  }

 
  if (successAnimation) {
    return (
      <div className="sizeCalcu-view_container">
        <h2 className="sizeCalcu-success">Success!</h2>
        <Player
          autoplay
          src={CheckCircle}
          style={{ height: "300px", width: "300px" }}
        ></Player>
      </div>
    );
  }

  if (redirectExternal) {
    let redirectUrlArray = params.redirectUrl.split("");
    if (redirectUrlArray.includes("?")) {
      return (window.location.href = `${params.redirectUrl}&sizify-id=${accountInfo.sizeId.id}`);
    } else {
      return (window.location.href = `${params.redirectUrl}?&sizify-id=${accountInfo.sizeId.id}`);
    }
  }
  let isloading;
  if (loading) {
    isloading = (
      <div className='sizeCalcu-loading_container'>
        <h2 className="sizeCalcu-view_title">Processing</h2>
        <Player
          autoplay
          loop
          src={SizifyLoading}
          style={{ height: "300px", width: "300px" }}
        ></Player>
      </div>
    );
  }


  let inputOption;
  if (!isImageUpload) {
    inputOption = (
      <input
        className="sizeCalcu-view_input"
        type="file"
        id="front"
        onChange={(e) => onImageUpload(e.target.files[0])}
      />
    );
  } else {
    inputOption = (
      <div className="sizeCalcu-view_inputContainer ">
        <h3 className="sizeCalcu-view_subTitle ">
          Do you want to use this image?
        </h3>
        <div className="sizeCalcu-view_buttonContainer ">
          <button
            className="btn btn-primary"
            onClick={() => onClickConfirmUpload()}
          >
            Yes
          </button>
          <button
            className="btn btn-outline-secondary --no-border  "
            onClick={() => backStepImageCount()}
          >
            No
          </button>
        </div>
      </div>
    );
  }

  let visibiltyClass = classNames({
    display_none: loading,
  });

  let imageClass = classNames({
    "sizeCalcu-view_image ": confirmUpload === false,
    display_none: confirmUpload,
  });
  let feetClass = classNames({
    "btn btn-primary sizeCalcu-height_button": heightInputOption === "feet",
    "btn btn-outline-secondary sizeCalcu-height_button ":
      heightInputOption !== "feet",
  });
  let cmClass = classNames({
    "btn btn-primary sizeCalcu-height_button": heightInputOption !== "feet",
    "btn btn-outline-secondary sizeCalcu-height_button ":
      heightInputOption === "feet",
  });

  let heightInput;
  if (heightInputOption === "feet") {
    heightInput = (
      <div className="sizeCalcu-height_feetContainer ">
        <div className="sizeCalcu-height_unitContainer">
          <label className="sizeCalcu-height_subTitle">Feet:</label>
          <select
            className="sizeCalcu-height_unitSelect"
            name="feet"
            value={feet}
            onChange={(e) => {
              handleFeetChange(e.target.value, "feet");
            }}
          >
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5" defaultValue>
              5
            </option>
            <option value="6">6</option>
            <option value="7">7</option>
          </select>
        </div>

        <div className="sizeCalcu-height_unitContainer ">
          <label className="sizeCalcu-height_subTitle">Inch:</label>
          <select
            className="sizeCalcu-height_unitSelect"
            name="inch"
            value={inch}
            onChange={(e) => {
              handleFeetChange(e.target.value, "inch");
            }}
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
          </select>
        </div>
      </div>
    );
  } else {
    heightInput = (
      <input
        className="sizeCalcu-height_input"
        id="1"
        onChange={(e) => onChangeHeight(e.target.value)}
        value={heightInCmData}
      ></input>
    );
  }

  let tryNewImageClass = classNames({
    display_none: !tryNewImageModal,
  });

  // NOTE: main modeType(below)

  // request person's height

  if (heightInCm === false && proceed === true && modeType === "main") {
    return (
      <div className="sizeCalcu-height_containerBg ">
        <Nav />
        <div className="sizeCalcu-height_container ">
          <h2 className="sizeCalcu-height_title ">How tall are you?</h2>
          <div className="sizeCalcu-height_buttonContainer ">
            <button
              className={feetClass}
              onClick={() => {
                toggleUnit("feet");
              }}
            >
              Feet
            </button>
            <button
              className={cmClass}
              onClick={() => {
                toggleUnit("cm");
              }}
            >
              Cm
            </button>
          </div>

          {heightInput}
          <button className="btn btn-primary" onClick={() => onClickHeight()}>
            {" "}
            Confirm
          </button>
        </div>
        <Footer />
      </div>
    );
  }

  // Main instruction page
  if (proceed === false && modeType === "main") {
    return (
      <div className="sizeCalcu-section_containerBg ">
        <Nav />
        <div className="sizeCalcu-header_container">
          <div className="sizeCalcu-header_textContainer">
            <h2 className="sizeCalcu-header_title">Instructions</h2>
            <h4 className="sizeCalcu-header_point">
                Get setup in 2 minutes or less
            </h4>
            <iframe  height="315" style={{maxWidth:'31rem'}} src="https://www.youtube.com/embed/lD6WFSb0DNo?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe>

          </div>
          </div>
          <div className='sizeCalcu-instruc-container'>
            <div className="sizeCalcu-header_textContainer">
              <h3 className="sizeCalcu-header_subTitle">Prepare Yourself</h3>
              <div className="sizeCalcu-instruc-step">
                <h4 className="sizeCalcu-header_point">
                    Wear form fitting clothes
                  </h4>
                  <div className="sizeCalcu-instruc-image">
                  <Img  
                      fluid={clothingImg}
                  />

                  </div>
              </div>
              <div className="sizeCalcu-instruc-step">
                <h4 className="sizeCalcu-header_point">
                Put long hair in a bun. Avoid hair running pass shoulders or above head
                </h4>
                <div className="sizeCalcu-instruc-image">
                <Img  
                    fluid={hairImg}
                />
                </div>
              
                </div>
              </div>
            <div className="sizeCalcu-header_textContainer">
              <h3 className="sizeCalcu-header_subTitle">Prepare Space</h3>
              <div className="sizeCalcu-instruc-step">
                <h4 className="sizeCalcu-header_point">
                Background free of people & objects

                </h4>
                <div className="sizeCalcu-instruc-image">
                <Img  
                    fluid={backgroundImg}
                />
                </div>
              </div>
              <div className="sizeCalcu-instruc-step">
                <h4 className="sizeCalcu-header_point">
                Ensure area is well lit
                </h4>
                <div className="sizeCalcu-instruc-image">
                <Img  
                    fluid={lightingImg}
                />
                </div>
              </div>
            </div>
            
            <div className="sizeCalcu-header_textContainer">
            <h3 className="sizeCalcu-header_subTitle">Take Picture</h3>
            <div className="sizeCalcu-instruc-step">
              <h4 className="sizeCalcu-header_point">
                  Place phone up right against wall <br></br>(Use front facing camera)
              </h4>
              <div className="sizeCalcu-instruc-image">
              <Img  
                  fluid={phoneFloorImg}
              />
              </div>
              </div>
            <div className="sizeCalcu-instruc-step">
              <h4 className="sizeCalcu-header_point">
              Stand 2 metres(3 large steps) away from phone

                </h4>
              <div className="sizeCalcu-instruc-image">
                <Img  
                  fluid={distanceFloorImg}
              />
              </div>
              </div>
            
            <div className="sizeCalcu-instruc-step">
              <h4 className="sizeCalcu-header_point">
              Take 2 pictures <br></br>(Use self timer on camera) 
              </h4>
              <div className="sizeCalcu-instruc-image">
                <Img  
                  fluid={twoPosesImg}
              />
              </div>
              </div>
        
            </div>
            
            <div className="sizeCalcu-header_textContainer">
              <h2 className="sizeCalcu-header_point">We never store your images</h2>
              <button
                className="btn btn-primary"
                onClick={() => onClickProceed()}
              >
                Proceed
              </button>
            </div>
           
          </div>
        <Footer />
      </div>
    );
  }

  let viewType;
  // Request Front View Image
  if (imageCount === 0 && modeType === "main") {
    viewType = (
      <div className="sizeCalcu-view_containerBg">
        <Nav />
        <div className={tryNewImageClass}>
          <PopUpModal
            modalVisible={toggleTryNewImage}
            modalVisible2={toggleTryNewImage}
            title="Try a different image"
            button1Text="Got it"
          />
        </div>

        <div className="sizeCalcu-view_container ">
          <h2 className="sizeCalcu-view_title ">Upload Front View </h2>
          {inputOption}
          <img id="image" src={image} className={imageClass} alt="person"></img>

          <canvas style={{ display: "none" }} width="100%" id="canvas"></canvas>
          <div id="resultImage"></div>
        </div>
        <Footer />
      </div>
    );
  }
  // Ensure image 1 is valid
  if (
    imageCount === 0 &&
    isImageUpload === true &&
    confirmView === false &&
    confirmUpload === true &&
    modeType === "main"
  ) {
    viewType = (
      <div className="sizeCalcu-view_containerBg">
        <Nav />
        {isloading}
        <div className={visibiltyClass}>
        <div className='sizeCalcu-view_container'>
          <h2 className="sizeCalcu-view_title">
            {" "}
            Are you the only thing highlighted in the image?{" "}
          </h2>
          <div className="sizeCalcu-view_buttonContainer">
            <button
              className="btn btn-primary"
              onClick={() => onClickConfirmView()}
            >
              Yes{" "}
            </button>
            <button
              className="btn btn-outline-secondary --no-border "
              onClick={() => backStepImageCount("invalid")}
            >
              No
            </button>
          </div>

          <img
            id="image"
            style={{ display: "none" }}
            src={image}
            alt="person"
          ></img>
          <canvas style={{ display: "none" }} width="100%" id="canvas"></canvas>
          <div id="resultImage" className='sizeCalcu-view_resultContainer'></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  // Request Side View Image
  if (imageCount === 1 && modeType === "main") {
    viewType = (
      <div className="sizeCalcu-view_containerBg">
        <Nav />
        <div className={tryNewImageClass}>
          <PopUpModal
            modalVisible={toggleTryNewImage}
            modalVisible2={toggleTryNewImage}
            title="Try a different image"
            button1Text="Got it"
          />
        </div>

        <div className="sizeCalcu-view_container ">
          <h2 className="sizeCalcu-view_title ">Upload Side View </h2>
          {inputOption}
          <img id="image" src={image} className={imageClass} alt="person"></img>

          <canvas style={{ display: "none" }} width="100%" id="canvas"></canvas>
          <div id="resultImage"></div>
        </div>
        <Footer />
      </div>
    );
  }
  // Ensure image 2 is valid
  if (
    imageCount === 1 &&
    isImageUpload === true &&
    confirmView === false &&
    confirmUpload === true &&
    modeType === "main"
  ) {
    viewType = (
      <div className="sizeCalcu-view_containerBg">
        <Nav />
        {isloading}

        <div className={visibiltyClass}>
          <div className='sizeCalcu-view_container'>
          <h2 className="sizeCalcu-view_title">
            {" "}
            Are you the only thing highlighted in the image?{" "}
          </h2>
          <div className="sizeCalcu-view_buttonContainer">
            <button
              className="btn btn-primary"
              onClick={() => onClickConfirmView()}
            >
              Yes{" "}
            </button>
            <button
              className="btn btn-outline-secondary --no-border "
              onClick={() => backStepImageCount("invalid")}
            >
              No
            </button>
          </div>

          <img
            style={{ display: "none" }}
            id="image"
            src={image}
            alt="person"
          ></img>
          <canvas style={{ display: "none" }} width="100%" id="canvas"></canvas>
          <div id="resultImage" className='sizeCalcu-view_resultContainer'></div>
        </div>
        </div>
        <Footer />
      </div>
    );
  }
  //Signify the end of the proccess
  if (imageCount === 2 && modeType === "main") {
    viewType = (
      <div className="sizeCalcu-view_containerBg">
        <Nav />
        {isloading}
        <div className={visibiltyClass}>
          <div className="sizeCalcu-view_resultContainer">
            <h2 className="sizeCalcu-view_title"> Great! All Done!</h2>
            <Link
              to="/app/my-size"
              className="btn btn-primary sizeCalcu-view_noDecor"
            >
              See Result
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  
  // NOTE: external modeType(below)
console.log(currentSlide,'current slide')
  //main instuctions external
  if (proceed === false && modeType === "external") {
    let carouselNextStepButton
    if(currentSlide === 7){
      carouselNextStepButton = (
        <div className='sizeCalcu-instruc-container'>
  
        <div className="sizeCalcu-header_textContainer">
    
          <button
            className="btn btn-primary"
            onClick={() => onClickProceed()}
          >
            Proceed
          </button>
        </div>
       
      </div>
      )
  
    }else{
        carouselNextStepButton = (
           <div className='sizeCalcu-instruc-container'>
  
           <div className="sizeCalcu-header_textContainer">
       
           <ButtonNext className="btn btn-primary" style={{textAlign:'center', margin:'0'}}>Continue</ButtonNext>
           </div>
          
         </div>
        )
    }
     
    return (
      <div className="sizeCalcu-section_containerBgExternal ">
        <div className="sizeCalcu-closeButton_containerExternal">
          <a href={params.redirectUrl}>
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="36px"
              height="36px"
              viewBox="0 0 24 24"
              aria-labelledby="closeIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
            >
              {" "}
              <title id="closeIconTitle">Close</title>{" "}
              <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
            </svg>
          </a>
        </div>
        <div className="sizeCalcu-header_container" style={{padding:'0', margin:'0'}}>
          <div className="sizeCalcu-header_textContainer" style={{ margin:'0 '}}>
            <ProgressBar title={'Get instant fit-match upon completion'} percent={75}/>
          </div>
           
        </div>
        <div className="sizeCalcu-header_container" style={{padding:'0', margin:'0'}} >
          <div className="sizeCalcu-header_textContainer" style={{ margin:'0 '}}>

            <h2 className="sizeCalcu-header_title" style={{margin:'0'}}>Instructions</h2>
            <h4 className="sizeCalcu-header_point">
                Get setup in 2 minutes or less. Your images are never saved
            </h4>
            {videoModal}
            <div className="sizeCalcu-instruc-image" style={{margin:'1rem 0'}}>
        
              <div className="sizeCalcu-carousel-topContainer">
              <ButtonBack style={{backgroundColor:'transparent', border:'0'}} >
              <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="chevronLeftIconTitle" stroke="#7D9FE8" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#7D9FE8"> <title id="chevronLeftIconTitle">Chevron Left</title> <polyline points="14 18 8 12 14 6 14 6"/> </svg>
              </ButtonBack>
              <ButtonLast className="sizeCalcu-header_subTitleExternal" style={{backgroundColor:'transparent', border:'0', width:'auto', fontSize:'1.2rem'}} >Skip</ButtonLast>
            </div>


            <Slider >
            <Slide index={0}>
            <div className="sizeCalcu-instruc-container" style={{height:'100%',justifyContent:'center', alignItems:'center'}}>
              <div  className="sizeCalcu-instruc-container" style={{justifyContent:'center'}}>
              <h4 className="sizeCalcu-header_point" style={{textAlign:'center',width:'100%'}}>
                      Getting Started Video(Optional)
                    </h4>
                      <button className="btn btn-solid-outline-secondary" style={{margin:'1rem 0 0 0', }} onClick={()=> {
                          setIsDemoVidOpen(true)
                          }}>
                        Watch in 52 seconds
                        </button>
              </div>
            
                  </div>
            </Slide>
              
            <Slide index={1}>
            <div>
                  <h4 className="sizeCalcu-header_point" style={{textAlign:'left'}}>
                      Wear form fitting clothes
                    </h4>
                  <Img  
                        fluid={clothingImg}
                    />
                
                  </div>
            </Slide>
            <Slide index={2}>
              <div>
                  <h4 className="sizeCalcu-header_point" style={{textAlign:'left'}}>
                  Put long hair in a bun. Avoid hair below shoulders or above head
                    </h4>
                  <Img  
                        fluid={hairImg}
                    />
                
                  </div></Slide>
              <Slide index={3}>      
                <div>
                  <h4 className="sizeCalcu-header_point" style={{textAlign:'left'}}>
                  Background free of people & objects
                    </h4>
                  <Img  
                        fluid={backgroundImg}
                    />
                
                  </div>
                </Slide>
                  <Slide index={4}>
                    <div>
                    <h4 className="sizeCalcu-header_point" style={{textAlign:'left'}} >
                    Ensure area is well lit
                      </h4>
                    <Img  
                          fluid={lightingImg}
                      />
                  
                    </div>

                  </Slide>
                  
                  <Slide index={5}>
                      <div>
                      <h4 className="sizeCalcu-header_point" style={{textAlign:'left'}}>
                      Place phone up right against wall <br></br>(Use front facing camera)
                        </h4>
                      <Img  
                            fluid={phoneFloorImg}
                        />
                    
                      </div>
                  </Slide>
                  <Slide index={6}>
                    <div>
                    <h4 className="sizeCalcu-header_point" style={{textAlign:'left'}}>
                    Stand 2 metres(3 large steps) away from phone
                      </h4>
                    <Img  
                          fluid={distanceFloorImg}
                      />
                  
                    </div>
                  </Slide>
                  <Slide index={7}>
                    <div>
                    <h4 className="sizeCalcu-header_point" style={{textAlign:'left'}}>
                    Take 2 pictures <br></br>(Use self timer on camera) 
                      </h4>
                    <Img  
                          fluid={twoPosesImg}
                      />
                  
                    </div>
                  </Slide>
          </Slider>
          <div className="sizeCalcu-instruc-container" style={{justifyContent:'center', margin:'1rem 0'}}>
              <Dot slide={0} className=" sizeCalcu-carousel-dot"/>
              <Dot slide={1} className="sizeCalcu-carousel-dot"/> 
              <Dot slide={2} className="sizeCalcu-carousel-dot"/>
              <Dot slide={3} className="sizeCalcu-carousel-dot"/>
              <Dot slide={4} className="sizeCalcu-carousel-dot"/> 
              <Dot slide={5} className="sizeCalcu-carousel-dot"/>
              <Dot slide={6} className="sizeCalcu-carousel-dot"/>
              <Dot slide={7} className="sizeCalcu-carousel-dot"/>
          </div>

              {carouselNextStepButton}
        
          
          
           
            </div>
            
          
            
            
         

          </div>
          </div>
          <div className={tryNewImageClass}>
          <PopUpModal
            modalVisible={toggleTryNewImage}
            modalVisible2={toggleTryNewImage}
            title={`Oops something went wrong. Upload a set of different images`}
            button1Text="Got it"
          />
        </div>
          
      </div>
    );
  }
  // ask user for height external
  if (heightInCm === false && proceed === true && modeType === "external") {
    return (
      <div className="sizeCalcu-height_containerBgExternal">
        <div className="sizeCalcu-closeButton_containerExternal">
          <a href={params.redirectUrl}>
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="36px"
              height="36px"
              viewBox="0 0 24 24"
              aria-labelledby="closeIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
            >
              {" "}
              <title id="closeIconTitle">Close</title>{" "}
              <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
            </svg>
          </a>
        </div>
        <div className="sizeCalcu-header_container" style={{padding:"0 1rem"}}>
        <ProgressBar title={'Get instant fit-match upon completion'} percent={85}/>
        </div>
        
        <div className="sizeCalcu-height_containerExternal ">
          <h2 className="sizeCalcu-height_titleExternal">How tall are you?</h2>
          <div className="sizeCalcu-height_buttonContainer ">
            <button
              className={feetClass}
              onClick={() => {
                toggleUnit("feet");
              }}
            >
              Feet
            </button>
            <button
              className={cmClass}
              onClick={() => {
                toggleUnit("cm");
              }}
            >
              Cm
            </button>
          </div>

          {heightInput}
          <button className="btn btn-primary" onClick={() => onClickHeight()}>
            {" "}
            Confirm
          </button>
        </div>
      </div>
    );
  }

  //Request Front image external
  if (imageCount === 0 && modeType === "external") {
    viewType = (
      <div className="sizeCalcu-view_containerBgExternal">
        <div className="sizeCalcu-closeButton_containerExternal">
          <a href={params.redirectUrl}>
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="36px"
              height="36px"
              viewBox="0 0 24 24"
              aria-labelledby="closeIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
            >
              {" "}
              <title id="closeIconTitle">Close</title>{" "}
              <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
            </svg>
          </a>
        </div>
        <div className={tryNewImageClass}>
          <PopUpModal
            modalVisible={toggleTryNewImage}
            modalVisible2={toggleTryNewImage}
            title="Try a different image"
            button1Text="Got it"
          />
        </div>
        <div className="sizeCalcu-header_container" style={{padding:"0 1rem"}}>
            <ProgressBar title={'Get instant fit-match upon completion'} percent={90}/>
        </div>
        <div className="sizeCalcu-view_container ">
          <h2 className="sizeCalcu-view_title ">Upload Front View </h2>
          {inputOption}
          <img id="image" src={image} className={imageClass} alt="person"></img>

          <canvas style={{ display: "none" }} width="100%" id="canvas"></canvas>
          <div id="resultImage"></div>
        </div>
      </div>
    );
  }

  //ensure front image is valid external
  if (
    imageCount === 0 &&
    isImageUpload === true &&
    confirmView === false &&
    confirmUpload === true &&
    modeType === "external"
  ) {
    viewType = (
      <div className="sizeCalcu-view_containerBgExternal">
        <div className="sizeCalcu-closeButton_containerExternal">
          <a href={params.redirectUrl}>
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="36px"
              height="36px"
              viewBox="0 0 24 24"
              aria-labelledby="closeIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
            >
              {" "}
              <title id="closeIconTitle">Close</title>{" "}
              <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
            </svg>
          </a>
        </div>
        <div className="sizeCalcu-header_container" style={{padding:"0 1rem"}}>
            <ProgressBar title={'Get instant fit-match upon completion'} percent={90}/>
        </div>
        <div className="sizeCalcu-view_container">
          {isloading}
          <div className={visibiltyClass}>
            <h2 className="sizeCalcu-view_title">
              {" "}
              Are you the only thing highlighted in the image?{" "}
            </h2>
            <div className="sizeCalcu-view_buttonContainer">
              <button
                className="btn btn-primary"
                onClick={() => onClickConfirmView()}
              >
                Yes{" "}
              </button>
              <button
                className="btn btn-outline-secondary --no-border "
                onClick={() => backStepImageCount("invalid")}
              >
                No
              </button>
            </div>

            <img
              id="image"
              style={{ display: "none" }}
              src={image}
              alt="person"
            ></img>
            <canvas
              style={{ display: "none" }}
              width="100%"
              id="canvas"
            ></canvas>
            <div id="resultImage" className='sizeCalcu-view_resultContainer'></div>
          </div>
        </div>
      </div>
    );
  }

  // Request Side View Image external
  if (imageCount === 1 && modeType === "external") {
    viewType = (
      <div className="sizeCalcu-view_containerBgExternal">
        <div className="sizeCalcu-closeButton_containerExternal">
          <a href={params.redirectUrl}>
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="36px"
              height="36px"
              viewBox="0 0 24 24"
              aria-labelledby="closeIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
            >
              {" "}
              <title id="closeIconTitle">Close</title>{" "}
              <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
            </svg>
          </a>
        </div>
        <div className={tryNewImageClass}>
          <PopUpModal
            modalVisible={toggleTryNewImage}
            modalVisible2={toggleTryNewImage}
            title="Try a different image"
            button1Text="Got it"
          />
        </div>
        <div className="sizeCalcu-header_container" style={{padding:"0 1rem"}}>
            <ProgressBar title={'Get instant fit-match upon completion'} percent={95}/>
        </div>
        <div className="sizeCalcu-view_container ">
          <h2 className="sizeCalcu-view_title ">Upload Side View </h2>
          {inputOption}
          <img id="image" src={image} className={imageClass} alt="person"></img>

          <canvas style={{ display: "none" }} width="100%" id="canvas"></canvas>
          <div id="resultImage"></div>
        </div>
      </div>
    );
  }
  // Ensure image 2 is valid external
  if (
    imageCount === 1 &&
    isImageUpload === true &&
    confirmView === false &&
    confirmUpload === true &&
    modeType === "external"
  ) {
    viewType = (
      <div className="sizeCalcu-view_containerBgExternal">
        <div className="sizeCalcu-closeButton_containerExternal">
          <a href={params.redirectUrl}>
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="36px"
              height="36px"
              viewBox="0 0 24 24"
              aria-labelledby="closeIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
            >
              {" "}
              <title id="closeIconTitle">Close</title>{" "}
              <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
            </svg>
          </a>
        </div>
        <div className="sizeCalcu-header_container" style={{padding:"0 1rem"}}>
            <ProgressBar title={'Get instant fit-match upon completion'} percent={100}/>
        </div>
        <div className="sizeCalcu-view_container">
          {isloading}

          <div className={visibiltyClass}>
            <h2 className="sizeCalcu-view_title">
              {" "}
              Are you the only thing highlighted in the image?{" "}
            </h2>
            <div className="sizeCalcu-view_buttonContainer">
              <button
                className="btn btn-primary"
                onClick={() => onClickConfirmView()}
              >
                Yes{" "}
              </button>
              <button
                className="btn btn-outline-secondary --no-border "
                onClick={() => backStepImageCount("invalid")}
              >
                No
              </button>
            </div>

            <img
              style={{ display: "none" }}
              id="image"
              src={image}
              alt="person"
            ></img>
            <canvas
              style={{ display: "none" }}
              width="100%"
              id="canvas"
            ></canvas>
            <div id="resultImage" className='sizeCalcu-view_resultContainer'></div>
          </div>
        </div>
      </div>
    );
  }

  //Signify the end of the proccess external
  if (imageCount === 2 && modeType === "external") {
    viewType = (
      <div className="sizeCalcu-view_containerBgExternal">
        <div className="sizeCalcu-closeButton_containerExternal">
          <a href={params.redirectUrl}>
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="36px"
              height="36px"
              viewBox="0 0 24 24"
              aria-labelledby="closeIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
            >
              {" "}
              <title id="closeIconTitle">Close</title>{" "}
              <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
            </svg>
          </a>
        </div>
        <div className="sizeCalcu-view_container">
          {isloading}
          <div className={visibiltyClass}>
            <h2 className="sizeCalcu-view_title">{loadingText}</h2>
            <Player
              autoplay
              loop
              src={SizifyLoading}
              style={{ height: "300px", width: "300px" }}
            ></Player>
          </div>
        </div>
      </div>
    );
  }

  //NOTE:renders all viewtypes
  return (
    <div className="App">
      <div>{viewType}</div>
    </div>
  );
};

export default SizeCalcu;

