import React from 'react'
import { Link } from "gatsby"
import * as QueryString from "query-string";
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import './ChooseSizeOption.css'

const ChooseSizeOption = ({location}) => {
    const params = QueryString.parse(location.search);
    return (
            <div className='chooseSizeOption_containerBgExternal'>
                <div className="chooseSizeOption-closeButton_container">
                    <a href={params.redirectUrl}>
                        <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="36px"
                        height="36px"
                        viewBox="0 0 24 24"
                        aria-labelledby="closeIconTitle"
                        stroke="hsla(221, 70%, 70%, 1)"
                        strokeWidth="1"
                        strokeLinecap="square"
                        strokeLinejoin="miter"
                        fill="none"
                        color="hsla(221, 70%, 70%, 1)"
                        >
                        {" "}
                        <title id="closeIconTitle">Close</title>{" "}
                        <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
                        </svg>
                    </a>
                </div>
                <div className='chooseSizeOption-header_container' style={{padding:'0'}}>
                <div className='chooseSizeOption-button_container '>
               <ProgressBar title={'Get instant fit-match upon completion'} percent={50}/>
                </div>
                    
                </div>
                
                <div className='chooseSizeOption-header_container '>
                <h1 className='chooseSizeOption-header_title'>Choose Size Entry Option:</h1>
                <div className='chooseSizeOption-button_container '>
                <Link className='btn btn-solid-outline-secondary chooseSizeOption-button' to={`/app/manual-size-entry?external=${params.external}&redirectUrl=${params.redirectUrl}`}>
                
                        <h3 className='chooseSizeOption-button_title'>Manual Entry </h3>
                        <h3  className='chooseSizeOption-button_modSuccess  '> Fastest</h3>

                 
                    <h4 className='chooseSizeOption-button_subTitle'>I already know my measurements</h4>
                </Link>

                </div>
                <div className='chooseSizeOption-button_container '>
                    <Link className='btn btn-solid-outline-secondary chooseSizeOption-button' to={`/app/size-calculator-external?external=${params.external}&redirectUrl=${params.redirectUrl}`} >
                        <h3 className='chooseSizeOption-button_title'>Image-Based Entry</h3>
                        <h3  className='chooseSizeOption-button_modInfo '>Auto</h3>
                        <h4 className='chooseSizeOption-button_subTitle'>I do not know my measurements</h4>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ChooseSizeOption

