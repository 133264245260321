import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "../auth/Auth";
import Nav from "../nav/Nav";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as QueryString from "query-string";
import Logo from "../../images/logo-04.png";
import { useLocation } from "@reach/router"
import "firebase/auth";
import firebase from "../../components/firebase/firebase";
import UserSignIn from "../../components/userSignIn/UserSignIn";

import "./Login.css";

const Login = () => {
  const location = useLocation();
  console.log(location)
  return (
    <UserSignIn
      location={location}
      login={true}
      title={"Login"}
      subTitle={"Get instant personalized fit recommendations"}
      alterSignInText={"New to Sizify?"}
      alterSignInLinkText={"Sign Up"}
      alterSignInLink={"/app/signup"}
    />
  );
};

export default Login;
