import React, { useContext } from "react";

import { navigate } from "gatsby"
import { AuthContext } from "../auth/Auth";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  console.log('private route')
  if (!currentUser && location.pathname !== `/app/login`) {
    console.log('user not logged in')
    navigate("/app/login")
    return null
  }
  return <Component {...rest} />
};

export default PrivateRoute;
