import React, { useContext, useState } from "react";
import { Link } from "gatsby"
import fireApp from "../../components/firebase/firebase";
import { AuthContext } from "../auth/Auth";
import "./Nav.scss";
import Logo from "../../images/logo-04.png";
import classNames from "classnames";

const Nav = () => {
  const { currentUser } = useContext(AuthContext);
  const [userDropdown, setUserDropdown] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(false);

  const onClickLogout = () => {
    fireApp
      .auth()
      .signOut()
      .then(function () {
        console.log("Sign-out successful.");
      })
      .catch(function (error) {
        console.log("An error happened.");
      });
  };

  const onToggleUserDropdown = () => {
    if (userDropdown) {
      setUserDropdown(false);
    } else {
      setUserDropdown(true);
    }
  };

  const onToggleMobileDropdown = (clickSource) => {
    if (clickSource === "logo") {
      setMobileDropdown(false);
    } else if (currentUser === null) {
      mobileDropdown ? setMobileDropdown(false) : setMobileDropdown(true);
    } else {
      return;
    }
  };

  let userDropdownClass = classNames({
    "navbar-user_dropdown ": userDropdown,
    "display_none ": !userDropdown,
  });

  let mobileDropdownClass = classNames({
    "navbar-mobile_optionsContainer ": mobileDropdown,
    "display_none ": !mobileDropdown,
  });
  let navOptions;
  if (currentUser === null) {
    navOptions = (
      <div>
        {/* <div className="navbar-text_desktop">
        <Link className="navbar-text" to="/shop/">
            For Shoppers
          </Link>
          <Link className="navbar-text " to="/app/login/">
            Log In
          </Link>
          <Link className="navbar-text navbar-text_highlight" to="/app/signup/">
            Sign up
          </Link>
        </div>
        <div className="navbar-text_mobile">
          <button
            onClick={() => onToggleMobileDropdown()}
            className="navbar-text_mobileMenu"
          >
            Menu
          </button>
        </div> */}
      </div>
    );
  } else {
    navOptions = (
      <div
        className="navbar-user_container "
        onClick={() => onToggleUserDropdown()}
      >
        <div className="navbar-circleBase ">
          <svg
            className="navbar-avatar_svg "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="35px"
            viewBox="0 0 24 24"
            aria-labelledby="personIconTitle"
            stroke="hsla(221, 50%, 50%, 1)"
            strokeWidth="1"
            strokeLinecap="square"
            strokeLinejoin="miter"
            fill="none"
            color="hsla(221, 50%, 50%, 1)"
          >
            {" "}
            <title id="personIconTitle">Person</title>{" "}
            <path d="M4,20 C4,17 8,17 10,15 C11,14 8,14 8,9 C8,5.667 9.333,4 12,4 C14.667,4 16,5.667 16,9 C16,14 13,14 14,15 C16,17 20,17 20,20" />{" "}
          </svg>
        </div>

        <div className={userDropdownClass}>
          <Link className="navbar-user_dropdownItem" to="/app/my-size/">
            <div className="navbar-text">My Size</div>
          </Link>
          <Link className="navbar-user_dropdownItem" to="/app/account/">
            <div className=" navbar-text ">Account</div>
          </Link>
          <div className="navbar-user_dropdownItem" onClick={onClickLogout}>
            <h2 className="navbar-text ">Sign Out</h2>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="navbar-container">
      <Link
        className="navbar-logo_container"
        to="/"
        onClick={() => onToggleMobileDropdown("logo")}
      >
        <img src={Logo} alt="logo" className="navbar-logo" />
        <h1 className="navbar-logo_text">Sizify</h1>
      </Link>

      <div className="navbar-option_container">{navOptions}</div>
      <div
        className={mobileDropdownClass}
        onClick={() => onToggleMobileDropdown()}
      >
        <Link className="navbar-mobile_options" to="/app/size-calculator-main/">
          Get Your Size
        </Link>
        <Link className="navbar-mobile_options " to="/app/login/">
          Log In
        </Link>
        <Link
          className="navbar-mobile_options navbar-mobile_optionsHighlight"
          to="/app/signup/"
        >
          Sign up
        </Link>
      </div>
    </div>
  );
};

export default Nav;
