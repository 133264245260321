import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./SizifyID.css";
let classNames = require("classnames");

const SizifyId = ({ id }) => {
  const [clipboard, setClipboard] = useState("Copy");

  useEffect(() => {
    if (clipboard === "Copied") {
      setTimeout(() => {
        setClipboard("Copy");
      }, 4000);
    }
  }, [clipboard]);

  let btnClass = classNames({
    "btn btn-solid-outline-secondary sizifyId-button": clipboard === "Copy",
    "btn btn-solid-outline-positive-secondary sizifyId-button":
      clipboard === "Copied",
  });

  return (
    <div className="sizifyId-section_container">
      <CopyToClipboard
        text={id}
        onCopy={() => {
          setClipboard("Copied");
        }}
      >
        <div>
          <h3 className="sizifyId-title ">Sizify ID</h3>
          <p className="sizifyId-subTitle">
            Copy ID & paste it into website's partnered with Sizify{" "}
          </p>
          <button className={btnClass}>
            {id}

            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              aria-labelledby="copyIconTitle"
              stroke="hsla(221, 70%, 70%, 1)"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
              fill="none"
              color="hsla(221, 70%, 70%, 1)"
              style={{ marginLeft: "1rem" }}
            >
              {" "}
              <title id="copyIconTitle">Copy</title>{" "}
              <rect width="12" height="14" x="8" y="7" />{" "}
              <polyline points="16 3 4 3 4 17" />{" "}
            </svg>
            {clipboard}
          </button>
        </div>
      </CopyToClipboard>
    </div>
  );
};

export default SizifyId;
