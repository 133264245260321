import React, { useEffect, useState } from "react";
import firebase from "../../components/firebase/firebase";
import CodeLoader from "../../components/codeLoader/CodeLoader";

let db = firebase.firestore();

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);
  const [sizeId, setSizeId] = useState(null);

  const [pending, setPending] = useState(true);
  console.log({currentUser:currentUser,accountInfo:accountInfo,panding:pending, sizeId})
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      console.log(user);
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (currentUser !== null) {
      unsubscribe = db
        .collection("users")
        .doc(currentUser.uid)
        .onSnapshot(
          (doc) => {
            let info = doc.data()
            console.log(info,'read');
            setAccountInfo(doc.data());
            setPending(false);
          },
          (err) => {
            console.log(err);
          }
        );
    }
    return unsubscribe;
  }, [currentUser]);

  if (pending) {
    return <CodeLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        accountInfo,
        sizeId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
