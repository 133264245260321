import React from "react";
import { Link } from "gatsby"
import "./PopUpModal.css";
let classNames = require("classnames");

const PopUpModal = ({
  modalVisible,
  modalVisible2,
  toNav,
  title,
  subTitle,
  button1Text,
  button2Text,
}) => {
  let button1TextClass = classNames({
    "btn btn-warning popUpModal-button_noTextDecor": button1Text !== undefined,
    display_none: button1Text === undefined,
  });

  let button2TextClass = classNames({
    "btn btn-outline-secondary --no-border": button2Text !== undefined,
    display_none: button2Text === undefined,
  });
  let mainButton;

  if (toNav !== undefined) {
    mainButton = (
      <Link className={button1TextClass} to={toNav}>
        {button1Text}
      </Link>
    );
  } else
    mainButton = (
      <button
        className={button1TextClass}
        onClick={(e) => {
          modalVisible();
        }}
      >
        {button1Text}
      </button>
    );
  
  
    return (
    <div
      className="popUpModal-modalBackground"
      onClick={(e) => {
        modalVisible2();
      }}
    >
      <div
        className="popUpModal-modalContainer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="popUpModal-closeWindowContainer">
          <svg
            onClick={(e) => {
              modalVisible2();
            }}
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            aria-labelledby="closeIconTitle"
            stroke="#b0b3b5"
            strokeWidth="1"
            strokeLinecap="square"
            strokeLinejoin="miter"
            fill="none"
            color="#b0b3b5"
          >
            {" "}
            <title id="closeIconTitle">Close</title>{" "}
            <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />{" "}
          </svg>
          <div className="popUpModal-text_container">
            <h3 className="popUpModal-section_title">{title}</h3>
            <p className="popUpModal-section_subTitle">{subTitle}</p>
            <div className="popUpModal-button_container ">
              {mainButton}
              <button
                className={button2TextClass}
                onClick={(e) => {
                  modalVisible2();
                }}
              >
                {button2Text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpModal;
