import React from "react";
import { Link } from "gatsby"
import ImageNotFound from "../../images/page-not-found.png";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <div>
      <div className="pageNotFound-header_container">
        <div className="pageNotFound-header_imageContainer">
          <img src={ImageNotFound} alt="Page Not Found" />
        </div>
        <div className="pageNotFound-header_textContainer">
          <h2 className="pageNotFound-header_title">
            Sorry, the page you were looking for was not found
          </h2>

          <div className="pageNotFound-header_optionContanier">
            <Link className="btn btn-primary pageNotFound-header_button" to="/">
              Return Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
