import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../auth/Auth";
import Nav from "../nav/Nav";
import Footer from "../../components/footer/Footer";
import PopUpModal from "../../components/popUpModal/PopUpModal";
import CodeLoader from "../../components/codeLoader/CodeLoader";
import SizifyID from "../../components/sizifyID/SizifyID";

import "./MySize.css";

let classNames = require("classnames");

const MySize = () => {
  const { accountInfo } = useContext(AuthContext);
  console.log(accountInfo);
  const [recalculateSizeVisible, setRecalculateSizeVisible] = useState(false);
  const [unitValues, setUnitValues] = useState({
    waist: "",
    hip: "",
    leg: "",
    chest: "",
  });
  const [unitSymbol, setUnitSymbol] = useState("inches");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log(accountInfo);
    if (accountInfo === null || accountInfo === undefined) {
      return;
    } else if (unitSymbol === "inches") {
      let waistInches = Math.round(accountInfo.size.waist / 2.54);
      let hipInches = Math.round(accountInfo.size.hip / 2.54);
      let legInches = Math.round(accountInfo.size.leg / 2.54);
      let chestInches = Math.round(accountInfo.size.chest / 2.54);

      setUnitValues({
        waist: waistInches,
        hip: hipInches,
        leg: legInches,
        chest: chestInches,
      });
    } else {
      setUnitValues({
        waist: accountInfo.size.waist,
        hip: accountInfo.size.hip,
        leg: accountInfo.size.leg,
        chest: accountInfo.size.chest,
      });
    }
  }, [unitSymbol, accountInfo]);

  const toggleUnit = (optionType) => {
    setUnitSymbol(optionType);
  };

  const toggleModalVisible = () => {
    console.log(recalculateSizeVisible);
    return recalculateSizeVisible
      ? setRecalculateSizeVisible(false)
      : setRecalculateSizeVisible(true);
  };
  let recalculateOption;
  if (recalculateSizeVisible) {
    recalculateOption = (
      <PopUpModal
        modalVisible2={toggleModalVisible}
        toNav="/app/size-calculator-main"
        title="Warning"
        subTitle="Recalculating your size will delete the current size values."
        button1Text="That's ok, overwrite"
        button2Text="No go back"
      />
    );
  }

  if (accountInfo === null || accountInfo === undefined) {
    return <CodeLoader />;
  }
  let inchesClass = classNames({
    "btn btn-primary mySize-section_button": unitSymbol === "inches",
    "btn btn-outline-secondary mySize-section_button ": unitSymbol !== "inches",
  });
  let cmClass = classNames({
    "btn btn-primary mySize-section_button": unitSymbol !== "inches",
    "btn btn-outline-secondary mySize-section_button ": unitSymbol === "inches",
  });
  // add Sizify gen 2 <SizifyID id={accountInfo.sizeId.id} />
  return (
    <div>
      <Nav />
      <div className="mySize-section_bg">
      

        {recalculateOption}
        <div className="mySize-section_container">
          <h2 className="mySize-section_title">My Clothing Size</h2>
          <div className="mySize-section_buttonContainer ">
            <button
              className={inchesClass}
              onClick={() => {
                toggleUnit("inches");
              }}
            >
              Inches
            </button>
            <button
              className={cmClass}
              onClick={() => {
                toggleUnit("cm");
              }}
            >
              Cm
            </button>
          </div>

          <div className="mySize-info_container">
            <div className="mySize-info_item ">
              <h3 className="mySize-section_subTitle">Waist</h3>
              <h4 className="mySize-section_number">
                {unitValues.waist}
                <em className="mySize-section_italic">{unitSymbol}</em>
              </h4>
            </div>
            <div className="mySize-info_item">
              <h3 className="mySize-section_subTitle">Hip</h3>
              <div>
                <h4 className="mySize-section_number">
                  {unitValues.hip}
                  <em className="mySize-section_italic">{unitSymbol}</em>
                </h4>
              </div>
            </div>
            <div className="mySize-info_item">
              <h3 className="mySize-section_subTitle">Leg</h3>
              <h4 className="mySize-section_number">
                {unitValues.leg}
                <em className="mySize-section_italic">{unitSymbol}</em>
              </h4>
            </div>
            <div className="mySize-info_item">
              <h3 className="mySize-section_subTitle">Chest</h3>
              <h4 className="mySize-section_number">
                {unitValues.chest}
                <em className="mySize-section_italic">{unitSymbol}</em>
              </h4>
            </div>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => toggleModalVisible()}
          >
            {" "}
            Update Clothing Size{" "}
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MySize;
