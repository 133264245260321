import React from "react";
import SizeCalcu from "../sizeCalcu/SizeCalcu";
import { useLocation } from "@reach/router"

const SizeCalcuExternal = () => {
  const location = useLocation();
 
  return <SizeCalcu location={location} modeType="external" />;
};

export default SizeCalcuExternal;
