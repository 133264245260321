import { Code } from "react-content-loader";
import React from "react";
import "./CodeLoader.css";

const CodeLoader = () => {
  return (
    <div className="codeLoader-container">
      <Code />
      <Code />
      <Code />
    </div>
  );
};

export default CodeLoader;
